.main_div {
    // background: radial-gradient(63.12% 77.29% at 50% 50%, #664A86 0%, #433566 66.45%, #302955 100%) ;
    // background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    // overflow: hidden;
    margin-top: 0px;
    border-top-left-radius: 30px;
    /* Set border radius for top-left corner */
    border-top-right-radius: 30px;

    // border:2px solid green;

    @media (min-width:821px) {
        width: 30%;
        padding: 0px 10px;
    }


}


.container {
    // width: 100vw;
    // height: 100%;

    display: flex;
    // align-items: center;
    justify-content: center;
    // border:2px solid green;


    // @media (min-width:821px) {
    //     width: 30vw;

    // }
    @media (min-width:821px) {
        width: 100%;
        // padding:0px 20%;
    }
}


.details__Area {
    background: radial-gradient(63.12% 77.29% at 50% 50%, #664A86 0%, #433566 66.45%, #302955 100%);
    position: absolute;
    top: 20vh;
    width: 95vw;
    // min-height: 400px;
    height: auto;
    // border:2px solid green;
    //     width: 347px;
    // height: 359px;
    // flex-shrink: 0;

    border-radius: 32px;
    // overflow: hidden;
    // border:2px solid green;
    // overflow: hidden;

    /* Set border radius for top-left corner */

    @media (min-width:821px) {
        width: 95%;
    }



    .boxCover {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .categoryContainer,
    .quizContainer,
    .subcatContainer {
        width: 100%;
        background-color: rgba(34, 129, 155, 1);
        border-radius: 10px;
        padding: 10px 10px;
        position: absolute;
        margin-top: 98px;
    }

    .subcatContainer {
        background-color: white;
    }

    .heightOne {
        height: 3px;
        position: relative;
    }

    .headingImage {
        width: 100%;
        max-width: 190px;
        height: auto;
        position: absolute;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .categorySlider {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 20px 0 0px;
        position: relative;
    }

    .allCategory {
        display: flex;
        overflow: scroll;
    }

    .allCategory::-webkit-scrollbar {
        display: none;
    }

    .category {
        margin: 0 9px 0 0;
        padding: 15px 25px 15px 25px;
        background-color: white;
        border-radius: 8px;
        text-align: center;
    }

    .categoryImage {
        width: 60px;
        height: 60px;
        justify-content: center;
        margin: 0 auto;
        background-color: rgba(249, 207, 207, 0.2);
        border-radius: 100%;
    }

    .category h4 {
        font: 500 17px/1 "Montserrat", sans-serif;
        margin-top: 20px;
    }

    .viewBox {
        display: inline-block;
        margin-top: 18px;
        border: 1px solid rgba(34, 129, 155, 1);
        border-radius: 8px;
        padding: 10px 13px;
        color: rgba(34, 129, 155, 1);
        font: 700 14px/17px "Montserrat", sans-serif;
    }

    .quizCover {
        padding: 20px 0 0px;
        position: relative;
        width: 100%;
    }

    .quizBox {
        width: 100%;
        border-radius: 4px;
        background-color: white;
        padding: 10px;
        box-sizing: border-box;
    }

    .reward {
        display: flex;
        // align-self: ;
        margin: auto;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: rgba(106, 56, 239, 1);
        // padding: 15px 0px;
        font: 500 14px/16px "Montserrat", sans-serif;
        border-radius: 4px;
        // position: relative;
        margin-bottom: 20px;
        // text-align: center;

        // border-radius: 13.268px;
        // border: 1px solid #FF1C00;
        // background: #FA5743;
    }

    //     .reward p {
    //         color: white;
    //         text-align: center;
    //         margin: auto;
    //         color: #FFF;
    // font-family: Roboto;
    // font-size: 18.985px;
    // font-style: normal;
    // font-weight: 700;
    // line-height: normal;
    //     }

    .quizInfo {
        width: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center
        ;
    }

    .quizImage {
        width: 115px;
        margin: 0 10px 0 0;
        height: 100px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;

        // border: 2px solid green;
        overflow: hidden;
        clip-path: path('M4.51684 26.1476C5.11469 17.6419 11.6622 10.7557 20.1269 9.73004L98.0651 0.286152C108.664 -0.998129 118 7.27495 118 17.9513V86.8426C118 96.6701 110.033 104.637 100.206 104.637H18.089C7.77019 104.637 -0.38508 95.8884 0.338423 85.5949L4.51684 26.1476Z');
        ;

    }

    .quizImage img {
        display: flex;
        align-self: center;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .quizdesc {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        margin-top: 15px;
    }

    .quizName {
        display: flex;
        justify-content: center;
        width: 100%;
        font: 700 16px/19px "Roboto", sans-serif;
        color: #FFC05C;

        font-family: Roboto;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .catName {
        width: 100%;
        display: flex;
        justify-content: center;
        font: 500 12px/17px "Montserrat", sans-serif;
        margin: 5px 0;
        color: #FFC05C;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .quizTime {
        width: 100%;
        justify-content: space-between;
    }

    .points {
        font: 400 12px/17px "Montserrat", sans-serif;
        position: relative;
        width: 25%;
        color: rgba(182, 182, 182, 1);
    }

    .points::after {
        content: "";
        position: absolute;
        height: 10px;
        width: 0px;
        top: 50%;
        transform: translateY(-50%);
        right: -10%;
        border-right: 1px solid rgba(182, 182, 182, 1);
    }

    .points:last-child::after {
        display: none;
    }

    .points img {
        margin-right: 5px;
    }

    .subCats {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 10px 0;
    }

    .subCats .parts {
        width: 45%;
        box-sizing: border-box;
        padding: 15px 12px;
        min-width: 176px;
        border-radius: 10px;
        margin: 10px 0;
        background-size: cover;
    }

    .parts h3 {
        width: 100%;
        color: white;
        margin-bottom: 70px;
        word-wrap: break-word;
        font: 600 23px/26px "Montserrat", sans-serif;
    }

    .viewMore {
        margin-bottom: 10px;
    }

    .viewMore p {
        color: white;
    }

    .viewMore img {
        margin-left: 5px;
    }

    .oneQuizCover {
        width: 100%;
        box-sizing: border-box;
        background-color: #23819b;
        height: 90vh;
        position: relative;
    }

    .quizHeader {

        position: relative;
        top: -70px;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 15px 10px;
        width: 100%;
        border-radius: 0 0 30px 30px;
        // border: 2px solid green;
    }

    .mt20 {
        margin-top: 20px;
        border:12px solid green!important;
    }

    .oneQuizInfo {
        margin-top: -70px;
        padding: 0px 0px;
        // position: relative;
        top: -70px;
        // width: 100vw;
    }

    .pointsBox {
        // justify-content: space-between;
        display: flex;
        justify-content: space-evenly;
        margin-top: -15px;
        gap:2%;
        // align-items: center;
    }

    .white {
        color: #fff;
    }

    .heroPoint {
        width: 29%;
        // background-color: #4494aa;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        border-radius: 16.772px;
        border: 1px solid rgba(255, 255, 233, 0.27);
        // background: rgba(142, 84, 233, 0.17);
        box-shadow: 0px 0px 0.4px 0px #8E54E9;
        width: 92px;
        height: 92px;
        flex-shrink: 0;

        border-radius: 16.772px;
border: 2px solid rgba(255,255,255,0.17);
background: rgba(142, 84, 233, 0.17);
box-shadow: 0px 0px 0.4px 0px #8E54E9;

     
        
    }

    .heroPoint h5 {
        width: 100%;

        color: white;
        font: 500 18px/22px "Montserrat", sans-serif;

    }

    .heroPoint img {
        margin: 5px;
        width: 20px;

    }

    .infoPoint {
        margin: 15px 0px;
        padding: 0px 25px;
        min-height: auto;
        color: white;

        word-wrap: break-word;
        overflow-wrap: break-word
        div{
            color: #FFF;
font-family: Roboto;
font-size: 102px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 116.667% */
letter-spacing: -0.24px;
        }
    }



    // .infoPoint p {
    //     width: fit-content;
    //     color: white;
    //     font: 500 18px/22px "Montserrat", sans-serif;
    //     word-wrap: wrap;
    //     overflow-wrap: break-word
    // }

    .oneQuizReward {
        position: absolute;
        bottom: 5%;
        margin: 0 auto;
        width: 95%;
        right: 0;
        left: 0;
        box-sizing: border-box;
    }

    .timeBox {
        width: 100%;
        height: 100vh;
        border: 1px solid black;
        background-size: cover;
        position: relative;
    }

    .timeLeft {
        position: absolute;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        background-color: white;
        border-radius: 100%;
        border: 10px solid yellow;
        top: 50%;
        left: 50%;
        outline: 10px solid white;
        transform: translate(-50%, -50%);
    }

    .timeLeft h2 {
        color: rgba(219, 38, 114, 1);
        font: 700 35px/35px "Montserrat", sans-serif;
    }

    .questionBox {
        width: 100%;
        height: 100vh;
    }

    .quesWarning {
        padding: 10px 15px;
        height: 100%;
        background-color: #23819b;
        width: 100%;
        position: relative;
    }

    .quesWarning h3 {
        color: white;
    }

    .quesWarning .proceed {
        position: absolute;
        bottom: 5%;
        margin: 0 auto;
        width: 95%;
        right: 0;
        left: 0;
    }

    .questionTimeLeft {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        background-color: white;
        border-radius: 100%;
        margin: 0 auto;
        border: 10px solid yellow;
        outline: 10px solid white;
    }

    .quesImage {
        text-align: center;
        padding-top: 30px;
    }

    .quesImage img {
        width: 90%;
        margin: 30px auto 0;
    }

    .flex {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }


}


@media (min-width:821px) {
    body {
        width: 30%;
        // border: 2px solid blue;
    }
}