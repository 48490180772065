// body{
//   overflow-y:scroll;
//   background-color: #302955!important;
// }
.parent {
    // background-color: rgba(102, 74, 134, 100%);
    background: radial-gradient(63.12% 77.29% at 50% 50%, #664A86 0%, #433566 66.45%, #302955 100%)!important;
    // border: 2px solid green;
    padding-bottom:20px ;
    // position: absolute;
    // @media (min-width: 821px) {
    //     width: 30vw;
    //     border: 2px solid red;
    //   }

    .menu{
        display: flex;
        flex-direction: column;
        position: absolute;
        gap:10px;
        top: 5%;
        right: 2%;

        @media (min-width: 821px) {
           
            top: 5%;
            right: 22%;
            // border: 2px solid red;   
          }
          
    }

    .profile__div {
        position: relative;
        top: 15%;
        right: 10px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        // .profile{
        //     border: 2px solid green;
        // }
        p {
            color: #352E57;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

    }
}



@media (min-width: 821px) {

    body {
      width: 30%;
      background-color: white;
    //   border:12px solid red;

    }

    .parent{
        width: 100%;
        border:2px solid yellow;
    }

    .menu{
        width: 100%;
        // border: 2px solid green;
    }

    .mission_sec,.continer{
        // border:12px solid red;
        width: 30%;
    }
  
    .dailog {
      width: 50%;
      border: 2px solid red;
    }
  
    .continer {
      width: 100%;
      // background-color: white;
    }
  
    .wrapper {
      width: 111%;
    }
  
    .oneQuizCover {
      width: 30%;
    }
  
    .wrapper_layout {
      width: 100%;
    }
  
    .wrapper_success {
      width: 100%;
    }
  
    #qns_img {
      width: 100%;
    }
  
    .main_div {
      width: 100%;
      min-height: 100vh;
      background: #FFF;
      // position: fixed;
      .spinewheel__parent{
      width: 100px;
      height: 30px;
      // border-radius: 50%;
      background-color: blue;
      position: fixed;
      right: 1010px;
      top: 400px;
      z-index: 100;
      .spinewheel{
          text-align:right;
          color:white;
          position:relative;
          z-index:60;
      }
      .spinewheel__image{
          position: relative;
          top:-30px;
          right:15px;
          width: 200px; /* Adjust width as needed */
          height: 200px; /* Adjust height as needed */
          overflow: hidden;
  
          img{
              // width: 100%;
              height: auto;
              animation: rotate 10s linear infinite; /* Adjust duration as needed */
              transform-origin: center center;
              z-index: 50;
          }
          
  
          
  
          @keyframes rotate {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
      }
  }
  
    }
  
    .main_div_sub {
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  
    }
  
    .timepopup {
      width: 30%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    #appbar {
      width: 100%
    }
  
    .mobile__width {
      width: 30%;
    }
  
    #image_div {
      width: 100%;
    }
  
    #qns_image {
      width: 100%;
  
  
    }
  
    #video {
      width: 100%;
    }
  
  
  
    .css-18uy99e-MuiStack-root {
      width: 30vw !important;
    }
  
    .css-1ggrdok {
      width: 30vw !important;
    }
  
  }



  .bottom_text {
    margin: 20px 15px 10px 15px;
    font-size: 2.3rem;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-family: Roboto;
font-size: 44px;
font-style: normal;
font-weight: 800;
line-height: 115.5%; /* 50.82px */
letter-spacing: 0.88px;

    background: linear-gradient(to right, #FFBA4D, #FFE3B6);
    -webkit-background-clip: text; /* For Safari */
    -webkit-text-fill-color: transparent; /* For Safari */
    background-clip: text;
    color: transparent;

    .color_text {
      color: #FFE3B6;
    }
  }

  .jingle_text {
    // width: 265px;
    margin: 10px 15px 50px 15px;
    font-size: 16px;
    font-family: 'Gantari', sans-serif;
    text-align: left;
    color: #9CAEB2;
    line-height: 108%;
    color: #FFF;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
  } 