// .parent{
//     width: 100%;
//     min-height: 180vh;
//     background-image: url('../../../../assets/theme6/bgart.png'); /* Specify the URL of your transparent image */
//     background-color: #FFC05C; /* Specify the color overlay (here, it's red with 50% opacity) */
//     opacity: 1;    
//     border:2px solid green;
// }

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.main_div {
    // background: radial-gradient(63.12% 77.29% at 50% 50%, #664A86 0%, #433566 66.45%, #302955 100%) ;
    // background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    height: auto;
    width: 100%;
    position: relative;
    top: 0;
    // border:2px solid green;
    // overflow: hidden;
    margin-top: 100px;
    padding-bottom: 50px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    // background: #FFC05C;
    // background-color: yellow;   
    // background-color: #FFC05C; 
    // border:4px solid green;

    @media (min-width:821px){
        width: 30%;
    }

}

.newBackgroundImage{
    // content: ""; /* Create a pseudo-element */
    // position: relative; /* Position the pseudo-element */
    // top: 0px;
    // left: 0;
    width: 100%; /* Cover the entire container */
    // min-height: 230vh;
    background-image: url('../../../../assets/theme6/bgart.png'); /* Specify the URL of your transparent image */
    background-color: #FFC05C; /* Specify the color overlay (here, it's red with 50% opacity) */
    opacity: 1; /* Adjust the opacity of the image */
    background-size: contain;
    // background-repeat: no-repeat;
    background-position: center;
    
    // padding:0px 10px;

    @media (min-width:821px){
        padding: 0px 1vw;
     
       

    }

}

.container {
    width: 100%;
    min-height: 100vh;

    display: flex;
    // align-items: center;
    justify-content: center;
    @media (min-width:821px){
        width: 28vw;
     
       

    }
}


.details__Area {
    background: radial-gradient(63.12% 77.29% at 50% 50%, #664A86 0%, #433566 66.45%, #302955 100%);
    // position: absolute;
    // top: 15vh;
    width: 95%;
    // min-height: 100%;
    border-radius: 30px;
    // border: 2px solid green;
    // border:2px solid green;
    position: relative;


    @media (min-width:821px){
        width: 100%;
    }

    .boxCover {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
    }

    .categoryContainer,
    .quizContainer,
    .subcatContainer {
        width: 100%;
        background-color: rgba(34, 129, 155, 1);
        border-radius: 10px;
        padding: 10px 10px;
        position: absolute;
        margin-top: 98px;
    }

    .subcatContainer {
        background-color: white;
    }

    .heightOne {
        height: 3px;
        position: relative;
    }

    .headingImage {
        width: 100%;
        max-width: 190px;
        height: auto;
        position: absolute;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .categorySlider {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 20px 0 0px;
        position: relative;
    }

    .allCategory {
        display: flex;
        overflow: scroll;
    }

    .allCategory::-webkit-scrollbar {
        display: none;
    }

    .category {
        margin: 0 9px 0 0;
        padding: 15px 25px 15px 25px;
        background-color: white;
        border-radius: 8px;
        text-align: center;
    }

    .categoryImage {
        width: 60px;
        height: 60px;
        justify-content: center;
        margin: 0 auto;
        background-color: rgba(249, 207, 207, 0.2);
        border-radius: 100%;
    }

    .category h4 {
        font: 500 17px/1 "Montserrat", sans-serif;
        margin-top: 20px;
    }

    .viewBox {
        display: inline-block;
        margin-top: 18px;
        border: 1px solid rgba(34, 129, 155, 1);
        border-radius: 8px;
        padding: 10px 13px;
        color: rgba(34, 129, 155, 1);
        font: 700 14px/17px "Montserrat", sans-serif;
    }

    .quizCover {
        padding: 20px 0 0px;
        position: relative;
        width: 100%;
    }

    .quizBox {
        width: 100%;
        border-radius: 4px;
        background-color: white;
        padding: 10px;
        box-sizing: border-box;
    }

    .reward {
        display: flex;
        // align-self: ;
        margin: auto;
        width: 50%;
        background-color: rgba(106, 56, 239, 1);
        padding: 15px 0px;
        font: 500 14px/16px "Montserrat", sans-serif;
        border-radius: 4px;
        text-align: center;

        border-radius: 13.268px;
        border: 1px solid #FF1C00;
        background: #FA5743;
    }

    .reward p {
        color: white;
        text-align: center;
        margin: auto;
        color: #FFF;
        font-family: Roboto;
        font-size: 18.985px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .quizInfo {
        width: 100%;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border:12px solid green;
    }

    .earn {
        margin-top: 10px;
        width: 176px;
        height: 71px;
        flex-shrink: 0;
        border-radius: 16.772px;
        border: 1px solid #5F4981;
        background: rgba(142, 84, 233, 0.17);
        box-shadow: 0px 0px 0.4px 0px #8E54E9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border:2px solid rgba(255, 255, 233, 0.17);
        .ibtn{
            position: relative;
            left:73px;
            top:-25px;
           
        }
        .info{
            width: 178px;
            height: 78px;
            // border:2px solid green;
            border: 1px solid #5F4981;
            background-color: #fff;
            border-radius: 8px 16px 8px 8px;
            position: relative;
            z-index: 1;
            top:0px;

            .nestedibtn{
                position: relative;
                left: 150px;
            }
            p{
                position: relative;
                top:-15px;
                padding:0px 15px;
                color: #302955;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */
letter-spacing: -0.24px;
text-align: center;
            }
        }
        .abs{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;
            
        }

        .coin {
            display: flex;
            gap: 5px;

            p {
                color: #FFC05C;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .coinsEarned {
            margin-top: 5px;
            p {
                color: #FFF;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: center;
            }
        }


    }

    .quizImage {
        width: 150px;
        // margin: 0 10px 0 0;
        height: 150px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 10px;
        justify-content: center;
        // border: 12px solid green;
        overflow: hidden;
        border-radius: 40%;
        position:relative;
        z-index: 9;
        border:4px solid #5F4981;
        display: flex;
        align-items: center;
        justify-content: center;
        // border:2px solid green;
        // border: 1px solid transparent; /* Hide the outer border */
        // box-shadow: inset 0 0 0 5px black; 
        // clip-path: path('M4.51684 26.1476C5.11469 17.6419 11.6622 10.7557 20.1269 9.73004L98.0651 0.286152C108.664 -0.998129 118 7.27495 118 17.9513V86.8426C118 96.6701 110.033 104.637 100.206 104.637H18.089C7.77019 104.637 -0.38508 95.8884 0.338423 85.5949L4.51684 26.1476Z');
        ;

    }

    .quizImage img {
        
        width: 110%;
        height: 110%;
        object-fit: cover;
      

    }

    .quizdesc {
        // display: flex;
        // flex-direction: column;
        // justify-items: center;
        // align-items: center;
        position: relative;
        bottom: 12px;
        width: 100%;
        box-sizing: border-box;
        // border:2px solid green;
    }

    .quizName {
        display: flex;
        justify-content: center;
        width: 100%;
        font: 600 16px/19px "Montserrat", sans-serif;
        color: #FFC05C;

        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .catName {
        width: 100%;
        display: flex;
        justify-content: center;
        font: 300 12px/17px "Montserrat", sans-serif;
        margin: 0px 0;
        color: #FFF;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .quizTime {
        width: 100%;
        justify-content: space-between;
    }

    .points {
        font: 400 12px/17px "Montserrat", sans-serif;
        position: relative;
        width: 25%;
        color: rgba(182, 182, 182, 1);
    }

    .points::after {
        content: "";
        position: absolute;
        height: 10px;
        width: 0px;
        top: 50%;
        transform: translateY(-50%);
        right: -10%;
        border-right: 1px solid rgba(182, 182, 182, 1);
    }

    .points:last-child::after {
        display: none;
    }

    .points img {
        margin-right: 5px;
    }

    .subCats {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 10px 0;
    }

    .subCats .parts {
        width: 45%;
        box-sizing: border-box;
        padding: 15px 12px;
        min-width: 176px;
        border-radius: 10px;
        margin: 10px 0;
        background-size: cover;
    }

    .parts h3 {
        width: 100%;
        color: white;
        margin-bottom: 70px;
        word-wrap: break-word;
        font: 600 23px/26px "Montserrat", sans-serif;
    }

    .viewMore {
        margin-bottom: 10px;
    }

    .viewMore p {
        color: white;
    }

    .viewMore img {
        margin-left: 5px;
    }

    .oneQuizCover {
        width: 100%;
        box-sizing: border-box;
        background-color: #23819b;
        height: 90vh;
        position: relative;
    }

    .quizHeader {

        position: relative;
        top: -70px;
        margin-bottom: -70px;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        padding: 15px 10px;
        width: 100%;
        border-radius: 0 0 30px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border: 2px solid green;
    }

    .mt20 {
        margin-top: 20px;
        // border:2px solid green;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .oneQuizInfo {
        padding: 20px 0px;
        position: relative;
        top: -70px;
        
        // width: 100vw;
    }

    .pointsBox {
        // justify-content: space-between;
        display: flex;
        justify-content: space-around;
        // align-items: center;
    }

    .white {
        color: #fff;
    }

    .heroPoint {
        width: 29%;
        background-color: #4494aa;
        border: 1px solid rgba(255, 255, 255, 0.18);
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        border-radius: 16.772px;
        border: 1px solid #5F4981;
        background: rgba(142, 84, 233, 0.17);
        box-shadow: 0px 0px 0.4px 0px #8E54E9;
        width: 92px;
        height: 92px;
        flex-shrink: 0;
    }

    .heroPoint h5 {
        width: 100%;

        color: white;
        font: 500 18px/22px "Montserrat", sans-serif;
    }

    .heroPoint img {
        margin: 5px;
        width: 20px;
    }

    .infoPoint {
        margin: 15px 0px;
        padding: 0px 10px;
        height: auto;
        color: white;

        word-wrap: break-word;
        overflow-wrap: break-word
    }



    // .infoPoint p {
    //     width: fit-content;
    //     color: white;
    //     font: 500 18px/22px "Montserrat", sans-serif;
    //     word-wrap: wrap;
    //     overflow-wrap: break-word
    // }

    .oneQuizReward {
        position: absolute;
        bottom: 5%;
        margin: 0 auto;
        width: 95%;
        right: 0;
        left: 0;
        box-sizing: border-box;
    }

    .timeBox {
        width: 100%;
        height: 100vh;
        border: 1px solid black;
        background-size: cover;
        position: relative;
    }

    .timeLeft {
        position: absolute;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        background-color: white;
        border-radius: 100%;
        border: 10px solid yellow;
        top: 50%;
        left: 50%;
        outline: 10px solid white;
        transform: translate(-50%, -50%);
    }

    .timeLeft h2 {
        color: rgba(219, 38, 114, 1);
        font: 700 35px/35px "Montserrat", sans-serif;
    }

    .questionBox {
        width: 100%;
        height: 100vh;
    }

    .quesWarning {
        padding: 10px 15px;
        height: 100%;
        background-color: #23819b;
        width: 100%;
        position: relative;
    }

    .quesWarning h3 {
        color: white;
    }

    .quesWarning .proceed {
        position: absolute;
        bottom: 5%;
        margin: 0 auto;
        width: 95%;
        right: 0;
        left: 0;
    }

    .questionTimeLeft {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        background-color: white;
        border-radius: 100%;
        margin: 0 auto;
        border: 10px solid yellow;
        outline: 10px solid white;
    }

    .quesImage {
        text-align: center;
        padding-top: 30px;
    }

    .quesImage img {
        width: 90%;
        margin: 30px auto 0;
    }

    // .flex {
    //     display: flex;
    //     justify-content: space-around;
    //     align-items: center;
    // }


}



.profile__detail__section {
    // border:2px solid green;
    // margin-top: 40px;    
    width: 90%;
    // border:2px solid red;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    gap: 15px;
    color: #DD3F52;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.49px;

    .email,
    .number {
        width: 100%;
        height: 50.236px;
        flex-shrink: 0;
        border-radius: 12.733px;
        border:2px solid rgba(255, 255, 233, 0.17);

        background: rgba(142, 84, 233, 0.17);
        box-shadow: 0px 0px 0.304px 0px #8E54E9;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8%;
        color: #fff;
        gap: 3px;
        .content{
            margin-left: 10px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            // font-size: 10.628px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.372px;
        }
    }

    .p_detail {
        width: 100%;
        display: flex;
        gap: 15px;
        background: #FFF;
        height: 50px;
        align-items: center;
        border-radius: 13px;
        padding-left: 10px;
        background: linear-gradient(to right,

                rgba(67, 108, 255, 0.1) 50%,
                /* First color with blur */

                rgba(251, 67, 255, 0.1) 100%
                /* Second color with blur */
            );

    }
}

.history__heading {
    display: flex;
    justify-content: space-between;
    // border:2px solid red;
    align-items: center;
    width: 80%;
    margin: 3vh 0px 20px -30px;
    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // h3{

    // }
}

.view_all {
    width: 79px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 11px 0px 0px 11px;
    border: 0.932px solid rgba(0, 0, 0, 0.00);
    background: #FA5743;
    box-shadow: 0px 3px 3.1px 0px #302955;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;

    p {
        text-decoration: none;
        color: #FFF;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        .link {
            text-decoration: none;
            color: #fff;
        }

    }
}


.history__card {
    display: flex;
    gap:5px;
    margin: 5px auto;
    border-radius: 20px;
    background: #FCFCFC;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    width: 98%;
    height: 120px;
    padding: 15px;
    border: 2px solid red;
    border-radius: 16.772px;
    border: 2px solid rgba(255, 255, 233, 0.17);
    background: rgba(142, 84, 233, 0.17);
    box-shadow: 0px 0px 0.4px 0px #8E54E9;
    // border: 2px solid green;


    .history__pic {
        border-radius: 11.514px;
        background: rgba(142, 84, 233, 0.17);
        width: 100px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: path('M4.51684 26.1476C5.11469 17.6419 11.6622 10.7557 20.1269 9.73004L98.0651 0.286152C108.664 -0.998129 118 7.27495 118 17.9513V86.8426C118 96.6701 110.033 104.637 100.206 104.637H18.089C7.77019 104.637 -0.38508 95.8884 0.338423 85.5949L4.51684 26.1476Z');


        img {
            // border:2px solid red;
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    }

    .history__details {
        width: 75%;
        height: 100%;
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: space-around;
        // margin-right: 100px;


        .top {
            height: 19px;
            display: flex;
            justify-content: space-between;
            // align-items: start;

            .name {
                color: #fff !important;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                // line-height: normal;
                letter-spacing: 0.32px;
            }

            .logo {
                width: 74px;
                height: 34px;
                flex-shrink: 0;
                display: flex;
             
                // border:1px solid green;
                gap: 5px;
                border: 2px solid red;
                position: relative;
                right: -20px;
                top: -20px;
                border-radius: 13px 16.772px 12px 12px;
                border: 2px solid #E8BA38;
                background: rgba(251, 193, 31, 0.80);
                box-shadow: 0px 0px 0.4px 0px #8E54E9;
                align-items: center;
                justify-content: center;
                color: #fff;

            }
        }

        .middle {
            color: #fff;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            color: #B9B9B9;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 12px */
        }


    }

}

.no__transaction{
    color: #fff;
    margin-top:90px;
}