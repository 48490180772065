.main_div{
    background:rgba(85, 61, 129, 1) ;
    // background: linear-gradient(180deg, rgba(142, 84, 233, 0.08) 0%, rgba(142, 84, 233, 0.00) 100%), linear-gradient(184deg, rgba(71, 118, 230, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    min-height: 100vh;
    position: absolute;
    top:0;
    overflow: hidden;
    margin-top: 20%;
    border-top-left-radius: 30px; /* Set border radius for top-left corner */
    border-top-right-radius: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width:821px){
        margin-top:10%;
        width: 30%;
    }
    // padding:10px auto;
    // border:2px solid red;
}

.history__heading {
    display: flex;
    justify-content: space-between;
    // border:2px solid red;
    align-items: center;
    width: 80%;
    margin: 4vh 0px 20px 25px;
    color: #FFF;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.history__card {
    display: flex;
    gap:5px;
    margin: 5px auto;
    border-radius: 20px;
    background: #FCFCFC;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 120px;
    padding: 15px;
    border: 2px solid red;
    border-radius: 16.772px;
    border: 2px solid rgba(255, 255, 233, 0.17);
    background: rgba(142, 84, 233, 0.17);
    box-shadow: 0px 0px 0.4px 0px #8E54E9;
    // border: 2px solid green;


    .history__pic {
        border-radius: 11.514px;
        background: rgba(142, 84, 233, 0.17);
        width: 100px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        clip-path: path('M4.51684 26.1476C5.11469 17.6419 11.6622 10.7557 20.1269 9.73004L98.0651 0.286152C108.664 -0.998129 118 7.27495 118 17.9513V86.8426C118 96.6701 110.033 104.637 100.206 104.637H18.089C7.77019 104.637 -0.38508 95.8884 0.338423 85.5949L4.51684 26.1476Z');


        img {
            // border:2px solid red;
            width: 100%;
            height: 100%;
            border-radius: inherit;
        }
    }

    .history__details {
        width: 75%;
        height: 100%;
        padding: 0px 5px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: space-around;
        // margin-right: 100px;


        .top {
            height: 19px;
            display: flex;
            justify-content: space-between;
            // align-items: start;

            .name {
                color: #fff !important;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                // line-height: normal;
                letter-spacing: 0.32px;
            }

            .logo {
                width: 74px;
                height: 34px;
                flex-shrink: 0;
                display: flex;
             
                // border:1px solid green;
                gap: 5px;
                border: 2px solid red;
                position: relative;
                right: -20px;
                top: -20px;
                border-radius: 13px 16.772px 12px 12px;
                border: 2px solid #E8BA38;
                background: rgba(251, 193, 31, 0.80);
                box-shadow: 0px 0px 0.4px 0px #8E54E9;
                align-items: center;
                justify-content: center;
                color: #fff;

            }
        }

        .middle {
            color: #fff;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            color: #B9B9B9;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 12px */
        }


    }

}


.no__transaction{
    color: #fff;
    width: 100%;
    height: 70vh;
    margin-top:30px;
    display: flex;
    align-items: center;
    justify-content: center;
}